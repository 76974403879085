<template>
    <r-drawer ref="rDialog" :dialogTitle="title" size="80%" :footer="false" :dialogLoading="dialogLoading">
        <div class="dialog-content">
            <el-row>
              <el-col :span="12" style="border-right: 1px solid #E9E9E9;height: 100%;padding: 0 10px 40px;">
                <el-form label-position="left" size="small" label-width="80px" :inline="true">
                  <div class="group-title"><span class="type-name">{{ form.typeName }}</span>{{ form.title }}</div>
                  <br>
                  <el-descriptions title="基本信息" :column="2" border direction="vertical">
                    <el-descriptions-item label="甲方">{{ form.partyAName }}</el-descriptions-item>
                    <el-descriptions-item label="已方">{{ form.partyBName  }}</el-descriptions-item>
                    <el-descriptions-item label="甲方联系方式">{{ form.partyAContact }}</el-descriptions-item>
                    <el-descriptions-item label="已方联系方式">{{ form.partyBContact }}</el-descriptions-item>
                    <el-descriptions-item label="签订日期">{{ form.date }}</el-descriptions-item>
                    <el-descriptions-item label="合同状态">{{ form.statusStr }}</el-descriptions-item>
                    <el-descriptions-item label="开始日期">{{ form.startDate }}</el-descriptions-item>
                    <el-descriptions-item label="结束日期">{{ form.periodStr }}</el-descriptions-item>
                    <el-descriptions-item label="村">{{ form.villageName }}</el-descriptions-item>
                    <el-descriptions-item label="支付方式">{{ form.paymentMethodStr }}</el-descriptions-item>
                  </el-descriptions>
                  
                  <!-- <el-row>
                    <el-col :span="12"><el-form-item label="甲方">{{ form.partyAName }}</el-form-item></el-col>
                    <el-col :span="12"><el-form-item label="已方">{{ form.partyBName }}</el-form-item></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12"><el-form-item label="签订日期">{{ form.date }}</el-form-item></el-col>
                    <el-col :span="12"><el-form-item label="合同期限">{{ form.periodStr }}</el-form-item></el-col>
                  </el-row> -->
                  <br>
                  <el-descriptions title="合同相关" :column="1" border direction="vertical">
                    <el-descriptions-item label="合同内容">{{ form.content }}</el-descriptions-item>
                    <el-descriptions-item label="附件">
                      <multiUploadFile v-model="form.annex" :enableUpload="false"></multiUploadFile>
                    </el-descriptions-item>   
                    <el-descriptions-item label="合同金额（总金额）" v-if="form.amountStr">
                      {{ form.amountStr }}
                    </el-descriptions-item> 
                  </el-descriptions>
                  <br>
                  <el-descriptions title="查看合同" :column="1" border direction="vertical">
                    <el-descriptions-item label="可见范围" style="line-height: 20px;">
                      <el-tag v-for="(item,index) in form.employee" :key="index" style="margin-right: 5px;">{{ item.name }}</el-tag>
                    </el-descriptions-item>
                  </el-descriptions>
                  <el-button type="primary" size="small" style="float: right;margin-top: 10px;" @click="editContract(form)">编辑合同</el-button>
                </el-form>
              </el-col>
              <el-col :span="12" style="padding: 0 10px;">
                <el-tabs type="border-card" value="received">
                  <el-tab-pane label="待办事项">
                    <el-form label-position="left" size="small" label-width="80px">
                      <div>
                        <el-card class="box-card" v-for="(item,index) in form.taskList" :key="index" style="margin-bottom: 10px;">
                          <div class="text item">
                            <el-form-item label="提醒日期">
                              {{ item.dateStr }}
                              <el-button style="float: right; padding: 3px 0" type="text" @click="taskDelete(item.id)">删除</el-button>
                              <el-button style="float: right; padding: 3px 0;margin-right: 10px;" type="text" @click="taskUpdate(item)">编辑</el-button>
                            </el-form-item>
                            <el-form-item label="提醒事项">{{ item.content }}</el-form-item>
                            <el-form-item label="提醒范围">
                              <el-tag size="mini" v-for="(item,index) in item.employee" :key="index" style="margin-left: 5px;">{{ item.name }}</el-tag>
                            </el-form-item>
                            <el-form-item label="提醒反馈">
                              <div v-for="(feedback,index) in item.feedback">
                                <div class="feedback-item">
                                  <div class="flex">
                                    <div class="flex-item">{{ feedback.departmentName }} {{ feedback.employeeName }}</div>
                                    <div class="flex-item">{{ feedback.createdAt }}</div>
                                  </div>
                                  <div>{{ feedback.content }}</div>
                                  <div v-if="feedback.annex.length>0">
                                    <multiUploadFile v-model="feedback.annex" :showLabel="false" :enableUpload="false"></multiUploadFile>
                                  </div>
                                </div>
                              </div>
                            </el-form-item>
                          </div>
                        </el-card>
                        <el-button type="primary" size="small" style="float: right;margin-top: 10px;" @click="taskUpdate(null)">添加</el-button>
                      </div>
                    </el-form>
                  </el-tab-pane>
                  <el-tab-pane :label="'已收款项（'+form.receivedMoney+'）'" name="received">
                    <el-form label-position="left" size="small" label-width="80px">
                      <div>
                        <el-card class="box-card" v-for="(item,index) in form.receivedList" :key="index" style="margin-bottom: 10px;">
                          <div class="text item">
                            <el-form-item label="收款日期">
                              {{ item.date }}
                              <el-button style="float: right; padding: 3px 0" type="text" @click="receivedDelete(item.id)">删除</el-button>
                              <el-button style="float: right; padding: 3px 0;margin-right: 10px;" type="text" @click="receivedUpdate(item)">编辑</el-button>
                            </el-form-item>
                            <el-form-item label="收款金额">{{ item.money }}</el-form-item>
                            <el-form-item label="备注">{{ item.remark }}</el-form-item>
                            <el-form-item label="图片">
                              <div v-if="item.annex.length>0">
                                <multiUploadFile v-model="item.annex" :showLabel="false" :enableUpload="false"></multiUploadFile>
                              </div>
                            </el-form-item>
                            <div class="flex" style="color: #606266;">
                              <div style="margin-right: auto;">录入人员：{{ item.departmentName}} {{ item.employeeName }}</div>
                              <div>录入时间：{{ item.createdAt}}</div>
                            </div>
                          </div>
                        </el-card>
                        <el-button type="primary" size="small" style="float: right;margin-top: 10px;" @click="receivedUpdate(null)">添加</el-button>
                      </div>
                    </el-form>
                  </el-tab-pane>
                </el-tabs>

              </el-col>
            </el-row>
        </div>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
        <update-contract ref="contractUpdate" @confirm="refresh"></update-contract>
        <update-contract-task ref="updateContractTask" @confirm="refresh"></update-contract-task>
        <update-contract-received ref="updateContractReceived" @confirm="refresh"></update-contract-received>
    </r-drawer>
</template>
<script>
import DepRoleModal from "@/components/dialog/DepRoleModal";
import { cloneDeep,isArray } from "@/utils";
import {
  contractDetail,
  contractPartyList, contractReceivedDelete,
  contractSave,
  contractTaskDelete,
  contractTypeList,
  contractTypeSave
} from "@/api/contract";
import multiUploadFile from "@/components/imageUpload/multiUploadFile";
import updateContract from "./update-contract";
import updateContractTask from "./update-contract-task";
import updateContractReceived from "@/views/contract/components/update-contract-received";
const defaultForm = {
    title:'',
    isLongTerm: 0,
    receivedMoney: 0,
    isNotAmount: 0,
    //可见范围人员
    employee: [],
    //附件
    annex: [],
}
export default {
    components: {updateContractReceived, DepRoleModal, multiUploadFile, updateContractTask, updateContract },
    data() {
        return {
            form: cloneDeep(defaultForm),
            title:'',
            rules:{
                title:[
                    { required: true, message: '请输入合同名称', trigger: ['change','blur'] }
                ],
                type:[
                    { required: true, message: '请选择合同类型', trigger: ['change','blur'] }
                ],
                partyA:[
                    { required: true, message: '请选择甲方', trigger: ['change','blur'] }
                ],
                partyB:[
                    { required: true, message: '请选择乙方', trigger: ['change','blur'] }
                ],
                date:[
                    { required: true, message: '请选择签订日期', trigger: ['change','blur'] }
                ],
                employee:[
                    { required: true, message: '请选择可见范围', trigger: ['change','blur'] }
                ],
            },
            modalConfig: {},
            isEdit: false,
            pidLoading: false,
            dialogVisible: false,
            contractTypes: [],
            partyAList: [],
            partyBList: [],
            dialogLoading: false,
        }
    },
    methods: {
        editContract(row){
          this.$refs.contractUpdate.open(row,'编辑合同');
        },
        taskDelete(taskId) {
          this.$confirm('您确定要删除该待办事项吗?')
              .then(_ => {
                contractTaskDelete({id: taskId}).then(res => {
                  this.$message.success(res.message);
                  this.refresh();
                });
              })
              .catch(_ => { });
        },
        receivedDelete(receivedId) {
          this.$confirm('您确定要删除该已收款项吗?')
              .then(_ => {
                contractReceivedDelete({id: receivedId}).then(res => {
                  this.$message.success(res.message);
                  this.refresh();
                });
              })
              .catch(_ => { });
        },
        taskUpdate(row) {
          this.$refs.updateContractTask.open(row ? row : { contractId: this.form.id }, row ? '编辑待办事项' : '添加待办事项');
        },
        receivedUpdate(row) {
          this.$refs.updateContractReceived.open(row ? row : { contractId: this.form.id }, row ? '编辑已收款项' : '添加已收款项');
        },
        open(form=null,title='新建合同') {
            this.title = title;
            if(form){
                this.form = Object.assign(cloneDeep(defaultForm),form);
            }
            this.remoteMethod();
            this.remoteMethodPartyA();
            this.remoteMethodPartyB();
            this.$refs.rDialog.handleOpen();
            if (this.form.id) {
              this.refresh();
            }
        },
        refresh() {
          this.dialogLoading = true;
          contractDetail({id: this.form.id}).then(res => {
            this.form = Object.assign(cloneDeep(defaultForm),res.data);
            this.dialogLoading = false;
          })
        },
        reset(){
            this.form = cloneDeep(defaultForm);
        },
        submit(done){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let form = cloneDeep(this.form);
                    contractSave(form).then(res=>{
                        this.$message.success('提交成功');
                        done(true);
                        this.$emit('confirm');
                    })
                    .catch(err=>{
                        done(false);
                    })
                } else {
                    done(false);
                }
            });
        },
        cChange(val){
            console.log(val)
        },
        remoteMethod(name) {
          this.pidLoading = true;
          contractTypeList({ keyword:name, id: this.form?.type || '', page: 1, pageSize: 20 }).then(res => {
            this.contractTypes = res.data.list;
            })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.pidLoading = false;
              })
        },
        remoteMethodPartyA(name) {
          this.pidLoading = true;
          contractPartyList({ keyword:name, id: this.form?.partyA || '', page: 1, pageSize: 20 }).then(res => {
            this.partyAList = res.data.list;
          })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.pidLoading = false;
              })
        },
        remoteMethodPartyB(name) {
          this.pidLoading = true;
          contractPartyList({ keyword:name, id: this.form?.partyB || '', page: 1, pageSize: 20 }).then(res => {
            this.partyBList = res.data.list;
              })
              .catch(err => {
                console.log(err)
              })
              .finally(() => {
                this.pidLoading = false;
              })
        },
        handleEmployee(selectType, title, model) {
          this.modalConfig = {
            type: 1,
            selectType,
            title,
            checkStrictly: true,
            model,
            checkList: this.form[model] || [],
            isStaff: 1,
            onlyStaff: 1
          };
          this.dialogVisible = true;
        },
        handleConfirm(val) {
          this.form[this.modalConfig['model']] = val;
          this.dialogVisible = false;
        },
        checkFile(){
          this.$refs.form.validateField('annex')
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-content {
    height: 100%;
    ::v-deep .el-descriptions-item__cell{
      width: 50%;
    }
}
.feedback-item {
  border: 1px solid #E9E9E9;
  padding: 10px;
  border-radius: 5px;
}
.flex {
  display: flex;
  align-items: center;
}
.flex-item {
  flex: 1;
}
.type-name {
  border: 1px solid #1e83e9;
  color: #1e83e9;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}
</style>