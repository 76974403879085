<template>
  <div>
    <div class="upload-list">
      <div class="file-list">
        <div class="file-item" v-for="(item, index) in uploadFileList" :key="item.url">
          <div class="file-item-thumb" @click="previewFile(item)">
            <el-image style="width: 110px; height: 110px" :src="item.url | imageUrl" fit="fill"
              :preview-src-list="previewList">
              <div class="image-error el-image__error" slot="error">
                <i :class="item.url | fileIcon" class="file-icon"></i>
              </div>
            </el-image>
            <div class="close-btn" @click.stop="handleRemove(index)" v-if="enableUpload">
              <i class="el-icon-close"></i>
            </div>
          </div>
          <div class="upload-label" v-if="showLabel">
            <div style="width: 110px">
              <el-input v-if="enableUpload" v-model="item.name" placeholder="输入文件名" size="mini" ></el-input>
              <span v-else>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <el-upload :http-request="handleUpload" class="fxy-upload" :headers="headers"
        v-if="enableUpload"
        :action="useOss ? ossUploadUrl : minioUploadUrl" :data="dataObj" :multiple="true" :on-remove="handleRemove"
        :on-success="handleUploadSuccess" :on-preview="handlePreview" :before-upload="beforeThumbUpload"
        :on-progress="uploadProgress" :on-error="uploadError" list-type="picture-card" ref="uploadFileCover"
        :show-file-list="false" :accept="accept">
        <i class="el-icon-plus" v-if="!imgUrl"></i>
      </el-upload>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { policy } from "@/api/oss";
const uuid = require('uuid')
import md5 from "js-md5";
import upload from '@/utils/ossUpload';
import store from "@/store";
import uploadProgress from "@/mixins/uploadProgress"
export default {
  name: "multiUpload",
  mixins: [uploadProgress],
  props: {
    //图片属性数组
    value: {
      type:Array,
      default(){
        return []
      }
    },
    //最大上传图片数量
    maxCount: {
      type: Number,
      default: 9,
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    enableUpload: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String,
      default: ".doc, .xls, .ppt, .pdf, .docx, .xlsx, .pptx"
    },
  },
  data() {
    return {
      dataObj: {
        policy: "",
        signature: "",
        key: "",
        ossaccessKeyId: "",
        dir: "",
        host: "",
      },
      show: false,
      dialogVisible: false,
      dialogImageUrl: null,
      base_api: process.env.BASE_API,
      url_api: process.env.VUE_APP_IMG_URL,
      imgUrl: null,
      useOss: false, //使用oss->true;使用MinIO->false
      ossUploadUrl: process.env.VUE_APP_IMG_URL,
      minioUploadUrl: process.env.VUE_APP_BASE_URL + "/api/upload/index",
      uploadFileList: [],
    };
  },
  filters: {
    imageUrl(value) {
      if (
        !!value &&
        (value.indexOf("http:") != -1 || value.indexOf("https:") != -1)
      ) {
        return value;
      } else {
        return (
          process.env.VUE_APP_IMG_URL + value
        );
      }
    },
    //处理文件图标
    fileIcon(val) {
      if (!val) return 'fxy-icon-guanbi1';
      let fileType = [{ name: 'pdf', icon: 'fxy-icon-PDF-01' }, { name: 'doc', icon: 'fxy-icon-WORD' }, { name: 'docx', icon: 'fxy-icon-WORD' }, { name: 'xls', icon: 'fxy-icon-Excel-01' }, { name: 'xlsx', icon: 'fxy-icon-Excel-01' }, { name: 'ppt', icon: 'fxy-icon-PPT' }, { name: 'pptx', icon: 'fxy-icon-PPT' }, { name: 'zip', icon: 'fxy-icon-ZIP-01' }, { name: 'mp4', icon: 'el-icon-video-camera-solid' }]
      let fileSuffix = val.split('.');
      let fileItem = fileType.find(el => el.name == fileSuffix[fileSuffix.length - 1]);
      if (fileItem) {
        return fileItem.icon
      } else {
        return 'fxy-icon-yichuli-002'
      }
    }
  },
  computed: {
    fileList() {
      let fileList = [];
      for (let i = 0; i < this.value?.length; i++) {
        fileList.push({ url: this.url_api + this.value[i], name: "" });
      }
      return fileList;
    },
    headers() {
      return {
        Authorization: store.state.userInfo.token || "",
        companyId: store.state.userInfo.companyId || "",
      };
    },
    previewList() {
      return this.uploadFileList.map(el => {
        return process.env.VUE_APP_IMG_URL + el.url;
      })
    }
  },

  watch: {
    value: {
      handler(val){
        this.uploadFileList = this.value;
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    beforeUpload(file) {
      let _self = this;
      if (!this.useOss) {
        //不使用oss不需要获取策略
        return true;
      }
      return new Promise((resolve, reject) => {
        policy()
          .then((response) => {
            _self.dataObj.policy = response.data.policy;
            _self.dataObj.signature = response.data.signature;
            _self.dataObj.ossAccessKeyId = response.data.accessKeyId;
            _self.dataObj.key =
              response.data.dir +
              "/" +
              md5(uuid.v1()) +
              "." +
              file.name.split(".").pop().toLowerCase();
            _self.dataObj.dir = response.data.dir;
            _self.dataObj.host = response.data.host;
            _self.ossUploadUrl = response.data.host;
            resolve(true);
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    finish() {
      this.$refs.cropper.getCropData((data) => {
        var base64String = data;
        var bytes = window.atob(base64String.split(",")[1]);
        var ab = new ArrayBuffer(bytes.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < bytes.length; i++) {
          ia[i] = bytes.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: "image/png" }); //type为图片的格式
        var fd = new FormData();
        fd.append("file", blob, "file.png");
        policy(fd)
          .then((res) => {
            this.imgUrl = process.env.VUE_APP_IMG_URL + res.data.url;
            this.cropperPoP = false;
            this.emitInput(this.imgUrl);
          })
          .catch((error) => { });
      });
    },
    clearFiles() {
      this.uploadFileList = [];
      this.$refs?.uploadFileCover?.clearFiles && this.$refs?.uploadFileCover?.clearFiles();
    },
    emitInput(fileList) {
      let value = [];
      this.$emit("input", fileList);
      this.$emit("change", fileList);
    },
    handleRemove(index) {
      this.uploadFileList.splice(index, 1);
      this.emitInput(this.uploadFileList);
    },
    handlePreview(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    },
    // 自定义上传操作
    handleUpload(op) {
      let dir = `images`
      upload(
        op.file,
        res => {
          let temp = {
            name: res.attachment,
            url: res.host + '/' + res.aliyunAddress
          }
          this.host = res.host
          op.onSuccess(temp)
        },
        err => {
          console.log(err);
          op.onError(err);
        },
        res => {
          op.onProgress(res)
        },
        dir
      )
    },
    handleUploadSuccess(res, file, fileList) {
      // let _res = { url: res.data.url, name: ""};
      this.updateProgress(file.uid, 'success');
      let _res = { url: res.url.replace('http:', 'https:').replace(process.env.VUE_APP_IMG_URL, ''), name: res.name };
      this.uploadFileList.push(_res);
      this.emitInput(this.uploadFileList);
    },
    handleExceed(files, fileList) {
      this.$message({
        message: "最多只能上传" + this.maxCount + "张图片",
        type: "warning",
        duration: 1000,
      });
    },
    previewFile(item) {
     let windowOpenTypes = '.doc, .xls, .ppt, .pdf, .docx, .xlsx, .pptx';
      windowOpenTypes.split(',').forEach(type => {
        if (item.url.indexOf(type.trim()) > -1) {
          window.open(this.$getStaticUrl(item.url))
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload--picture-card {
  width: 110px;
  height: 110px;
  line-height: 110px;

  i {
    font-size: 24px;
  }
}

::v-deep .el-upload-list--picture-card {
  .el-upload-list__item {
    width: 110px;
    height: 110px;
    line-height: 110px;
    position: relative;

    .el-upload-list__item-status-label .el-icon-upload-success {
      display: flow-root;
    }
  }
}

::v-deep .el-upload-list__item {
  transition: none !important;
}

::v-deep .el-upload-list__item-thumbnail {
  /* 图片在方框内显示长边 */
  object-fit: scale-down !important;
}

.upload-label {
  display: flex;
  span{
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
  }
  .el-input:not(:first-child) {
    margin-left: 8px;
  }
}

.file-icon {
  font-size: 48px !important;
}

.upload-list {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.file-list {
  display: flex;

  .file-item {
    width: 110px;
    margin-right: 10px;

    .file-item-thumb {
      width: 110px;
      height: 110px;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      margin-bottom: 8px;
      overflow: hidden;
      cursor: pointer;
      position: relative;

      .close-btn {
        position: absolute;
        width: 100px;
        height: 50px;
        right: -83px;
        top: -32px;
        background: rgba(0, 0, 0, 0.6);
        text-align: center;
        padding-top: 18px;
        transform: rotate(45deg);
        cursor: pointer;
        transition: transform 0.3s;

        i {
          font-size: 26px;
          color: #fff;
          transform: rotate(-45deg);
        }
      }

      &:hover {
        .close-btn {
          transform: translateY(20px) translateX(-45px) rotate(45deg);
        }
      }
    }
  }
}
</style>
